import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@mui/material/Box";
import ConfirmDeleteModal from "../../components/images/ConfirmDeleteModal"; // Import the ConfirmDeleteModal

export default function CaptureActionsScrubber({
  disabled,
  onSave,
  onDuplicate,
  onRemove,
  onTransfer,
  disableSave,
  onCloseModal,
  handeRefetch,
}) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = () => {
    onRemove().then(() => {
      handeRefetch();
      onCloseModal();
      setShowModal(false);
    });
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex">
          <Box mr={1} flexGrow={1}>
            <Button
              disabled={disabled || !onRemove}
              id="RemoveAsset"
              data-id="remove-asset"
              fullWidth
              variant="outlined"
              onClick={handleOpenModal}
            >
              Delete
            </Button>
          </Box>
          <Box mr={1} flexGrow={1}>
            <Button
              disabled={disabled || !onTransfer}
              id="TransferAsset"
              data-id="transfer-asset"
              fullWidth
              variant="outlined"
              onClick={onTransfer}
            >
              Transfer
            </Button>
          </Box>
          {onDuplicate && (
            <Box mr={1} flexGrow={1}>
              <Button
                disabled={disabled || !onDuplicate}
                id="duplicateAsset"
                data-id="duplicate-asset"
                fullWidth
                variant="outlined"
                onClick={() =>
                  onDuplicate().then(() => {
                    handeRefetch();
                  })
                }
              >
                Duplicate
              </Button>
            </Box>
          )}
          {onSave && (
            <Box mr={1} flexGrow={1}>
              <Button
                disabled={disabled || !onSave || disableSave}
                id="saveAsset"
                data-id="save-asset"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSave}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </CardContent>
      {showModal && (
        <ConfirmDeleteModal
          title="Delete Asset"
          descr="Are you sure you want to delete the selected asset?"
          onClose={handleCloseModal}
          onDelete={handleDelete}
        />
      )}
    </Card>
  );
}
