import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { useFormikContext } from "formik";

import Autocomplete from "src/components/form/Autocomplete";
import advancedSearchFieldOptions from "src/constants/advancedSearchFieldOptions";
import Input from "src/components/form/Input";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 16,
  },
  field: {
    marginBottom: 12,
  },
  toggleLabel: {
    marginBottom: 8
  },
  toggleGroup: {
    display: "flex",
    justifyContent: "space-between"
  },
  toggleButton: {
    textTransform: "none",
    color: theme.palette.text.secondary,
    borderColor: theme.palette.grey[400],
    "&.Mui-selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
      }
    },
  }
}))

const AdvancedSearchCriteria = ({ index, removeCriterion }) => {
  const { setFieldValue, values } = useFormikContext();
  const classes = useStyles();

  const handleToggleChange = (event, newMatchType) => {
    if (newMatchType !== null) {
      setFieldValue(`criteria[${index}].matchType`, newMatchType);
    }
  };

  return (
    <Box className={classes.container}>
      <Input
        className={classes.field}
        name={`criteria[${index}].text`}
        label="Search"
      />
      <Autocomplete
        className={classes.field}
        name={`criteria[${index}].field`}
        options={advancedSearchFieldOptions}
        label="Search Field"
        placeholder="Select a Field"
      />
      <Typography className={classes.toggleLabel} fontWeight={600} color="textSecondary" variant="body2">
        Match Type
      </Typography>
      <Box className={classes.toggleGroup}>
        <ToggleButtonGroup
          size="small"
          value={values.criteria[index].matchType}
          exclusive
          onChange={handleToggleChange}
        >
          <ToggleButton value="~" className={classes.toggleButton}>Partial</ToggleButton>
          <ToggleButton value="=" className={classes.toggleButton}>Exact</ToggleButton>
        </ToggleButtonGroup>
        {values.criteria.length > 1 && (
          <IconButton size="small" onClick={() => removeCriterion(index)}>
            <DeleteIcon/>
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

AdvancedSearchCriteria.propTypes = {
  index: PropTypes.number.isRequired,
  removeCriterion: PropTypes.func.isRequired,
};

export default AdvancedSearchCriteria;