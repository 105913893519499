import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import useAuth from "../../../../hooks/useAuth";
import useQuery from "../../../../hooks/useQuery";
import useMutation from "../../../../hooks/useMutation";
import { DELETE_INVENTORY_REQUEST_MUTATION } from "../../../../constants/graphql/mutations";
import AttachmentModal from "../AttachmentModal";
import InventoryRequestModal from "../InventoryRequestModal";
import ConfirmDeleteModal from "../../../../components/images/ConfirmDeleteModal";
import Share from "../share/Share";
import ManualLocationCheckout from "./ManualLocationCheckout";
import useNotification from "src/hooks/notification";
import { isVaSubdomain } from "../../../../utils/inventoryRequests";

export default function OrderActions({
  inventoryRequest,
  toggleAssets,
  reFetch,
  updateInventoryRequest,
  removeInventoryItem,
  changeAllInventoryItems,
}) {
  const { notifyError } = useNotification();
  const [removing, setRemoving] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const { user } = useAuth();
  const inventoryRequestItems = inventoryRequest.inventoryItems;
  const [deleteInventoryRequest] = useMutation(DELETE_INVENTORY_REQUEST_MUTATION);
  const [attachment, setAttachment] = useState(false);
  const [removeItem, setRemoveItem] = useState(false);
  const [manualLocation, setManualLocation] = useState(false);
  const isAdmin = user.role === "admin";

  const toggleManualLocation = () => {
    setManualLocation(v => !v);
  };

  const isVA = isVaSubdomain(); // Check if it's a VA subdomain

  const toggleOpenModal = useCallback(() => {
    if (query.get("inventoryRequestId")) {
      history.push(window.location.pathname);
    } else {
      history.push({
        pathname: window.location.pathname,
        search: `?inventoryRequestId=${inventoryRequest.id}`,
      });
    }
  }, [query, history]);

  const toggleAttachmentModal = useCallback(() => {
    setAttachment(v => !v);
  }, [setAttachment]);

  const toggleRemoveModal = useCallback(() => {
    setRemoveItem(v => !v);
  }, [setRemoveItem]);

  const handleDelete = useCallback(() => {
    setRemoving(true);
    deleteInventoryRequest({
      variables: { id: inventoryRequest.id },
      onSuccess: () => {
        reFetch().then(() => {
          setRemoving(false);
        });
      },
      onFailure: data => {
        notifyError(data.submit);
        setRemoving(false);
      },
    });
  }, [deleteInventoryRequest, inventoryRequest, reFetch, notifyError]);

  const handleUpdateInventoryItem = useCallback(
    (id, item) => {
      return updateInventoryRequest(inventoryRequest.id, {
        ...inventoryRequest,
        inventoryItems: inventoryRequest.inventoryItems.map(v =>
          v.id === id
            ? {
                ...v,
                ...item,
              }
            : v
        ),
      });
    },
    [updateInventoryRequest, inventoryRequest]
  );



  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
      <Tooltip title="File Cabinet">
        <IconButton color="inherit" onClick={toggleAssets}>
          <LocalMallOutlinedIcon />
        </IconButton>
      </Tooltip>
      {window.location.pathname.includes("/inventory") &&
        user.company.settings.manual_location_checkout && (
          <Tooltip title="Manual Location Checkout">
            <IconButton color="inherit" onClick={toggleManualLocation}>
              <ChecklistIcon />
            </IconButton>
          </Tooltip>
        )}
      <Tooltip title="Attachments">
        <IconButton color="inherit" onClick={toggleAttachmentModal}>
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={isVA ? "Edit Task Order" : "Edit Order"}>
        <IconButton color="inherit" onClick={toggleOpenModal}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Share
        title={isVA ? "Share Task Order" : "Share Order"}
        inventoryRequest={inventoryRequest}
      />
      {isAdmin &&
        (removing ? (
          <Box display="inline-block" p={1.5} height={48}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Tooltip title={isVA ? "Delete Task Order" : "Delete Order"}>
            <IconButton color="inherit" onClick={toggleRemoveModal}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ))}
      {attachment && (
        <AttachmentModal inventoryRequestId={inventoryRequest.id} onClose={toggleAttachmentModal} />
      )}
      {query.get("inventoryRequestId") === inventoryRequest.id && (
        <InventoryRequestModal
          inventoryRequest={inventoryRequest}
          inventoryRequestItems={inventoryRequestItems}
          updateInventoryRequest={updateInventoryRequest}
          changeAllInventoryItems={changeAllInventoryItems}
          updateInventoryItem={handleUpdateInventoryItem}
          removeInventoryItem={removeInventoryItem}
          onClose={toggleOpenModal}
          reFetch={reFetch}
        />
      )}
      {removeItem && (
        <ConfirmDeleteModal
          title="Delete Order"
          descr="Are you sure you want to delete this order?"
          onClose={() => setRemoveItem(null)}
          onDelete={handleDelete}
        />
      )}
      {manualLocation && (
        <ManualLocationCheckout
          inventoryRequestId={inventoryRequest.id}
          onClose={toggleManualLocation}
        />
      )}
    </Box>
  );
}
