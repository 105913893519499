import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import DeliveryDateField from "../../../views/shopping/Cart/SimpleCheckout/DeliveryDateField";
import Input from "../../../components/form/Input";
import { isAdminUser } from "../../../constants/user";
import { useCustomFields } from "../../../contexts/customFields";
import { getWO } from "../../inventory/requests/orders/RequestRow";
import DateTimePicker from "../../../components/ui/DateTimePicker";
import { usePermissions } from "../../../contexts/permissions";
import InventoryItemAddress from "../../../views/shopping/Cart/InventoryItemAddress";
import Requestor from "../../inventory/requests/orders/Requestor";
import { isStartDateGreaterEndDate } from "./WizardCheckout/Step2";
import ResourcesField from "./ResourcesField";
import { addHours } from "./CartItemFields";
import CreatedByEmail from "./CreatedByEmail";
import DispositionField from "src/views/shopping/Cart/DispositionField";

export default function CartForm({
  email,
  user: inventoryRequestUser,
  changeAllInventoryItems,
  workOrderNumber,
  revisionNumber,
  children,
  commentDisabled, // Disables comments in inventory request modal if within cutoff window
}) {
  const { values, setFieldValue } = useFormikContext();
  const { user } = usePermissions();
  const [display] = useCustomFields();
  const isAdmin = isAdminUser(user.email);

  const minDate = useMemo(() => {
    return new addHours(48)
      .toISOString()
      .split(".")[0]
      .slice(0, -3);
  }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {display.ir_email && <CreatedByEmail email={email} user={inventoryRequestUser} />}
            {display.ir_cc_emails && (
              <Grid item xs={12} md={12}>
                <Input name="ccEmails" label="CC Emails" />
              </Grid>
            )}
            {workOrderNumber && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  label="Order Number"
                  fullWidth
                  size="small"
                  value={getWO({ workOrderNumber, revisionNumber })}
                  variant="outlined"
                  margin="none"
                />
              </Grid>
            )}
            {display.ir_location && (
              <Grid item xs={12} md={12}>
                <InventoryItemAddress
                  inventoryItem={{ location: values.location }}
                  onChangeField={() => {}}
                  onChange={v => {
                    setFieldValue("location", v.location);
                  }}
                />
              </Grid>
            )}
            {display.ir_disposition && (
              <Grid item xs={12}>
                <DispositionField
                  name="disposition"
                  onChange={value =>
                    changeAllInventoryItems({
                      disposition: value,
                    })
                  }
                />
              </Grid>
            )}
            {display.ir_po_number && (
              <Grid item xs={12} md={workOrderNumber ? 6 : 12}>
                <Input name="poNumber" label="PO Number" />
              </Grid>
            )}
            <Grid item xs={6}>
              <Input name="contactName" label="Contact Name" />
            </Grid>
            <Grid item xs={6}>
              <Input name="contactPhoneNumber" label="Contact Phone Number" />
            </Grid>
            {display.ir_comment && (
              <Grid item xs={12}>
                <Input type="text" name="comment" label="Comment" disabled={commentDisabled} />
              </Grid>
            )}
            {display.ir_resources && (
              <Grid item xs={12}>
                <ResourcesField />
              </Grid>
            )}
            {display.ir_designer && (
              <Grid item xs={12}>
                <Requestor />
              </Grid>
            )}
            {display.ir_start_date && (
              <Grid item xs={12} md={6}>
                <DateTimePicker
                  label="Start Date and Time"
                  minDate={isAdmin ? undefined : minDate}
                  defaultValue={values.startDate ? `${values.startDate}T${values.startTime}` : ""}
                  onChange={({ date, time }) => {
                    setFieldValue("startDate", date);
                    setFieldValue("startTime", time);
                  }}
                />
              </Grid>
            )}
            {display.ir_end_date && (
              <Grid item xs={12} md={6}>
                <DateTimePicker
                  label="End Date and Time"
                  minDate={
                    isAdmin
                      ? undefined
                      : values.startDate
                      ? `${values.startDate}T${values.startTime}`
                      : minDate
                  }
                  error={isStartDateGreaterEndDate(values)}
                  defaultValue={values.endDate ? `${values.endDate}T${values.endTime}` : ""}
                  onChange={({ date, time }) => {
                    setFieldValue("endDate", date);
                    setFieldValue("endTime", time);
                  }}
                />
              </Grid>
            )}
            {display.ir_delivery_date && (
              <Grid item xs={12} md={6}>
                <DeliveryDateField minDate={isAdmin ? undefined : minDate} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {children}
    </>
  );
}
