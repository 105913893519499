import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Backdrop from "@mui/material/Backdrop";

const useStyles = makeStyles(() => ({
  paper: props => ({
    width: "100%",
    maxWidth: props.maxWidth,
  }),
  paperScrollBody: {
    maxHeight: "none",
    margin: "auto",
  },
  scrollBody: {
    padding: "48px 12px",
    display: "flex",
    boxSizing: "border-box",
    alignItems: "flex-start",
  },
}));

const DisableTransitionComponent = ({ children }) => children;

const ActionModal = ({
  maxWidth,
  onBack,
  onClose,
  showClose,
  title,
  instructions,
  id,
  disableTransition,
  children,
  ...rest
}) => {
  // Declare style hook to use classes
  const classes = useStyles({ maxWidth });

  const uuid = id || "modal";

  return (
    <Dialog
      {...rest}
      maxWidth={false}
      scroll="body"
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      PaperProps={{ style: { maxHeight: "none !important" } }}
      classes={{
        paper: classes.paper,
        paperScrollBody: classes.paperScrollBody,
        scrollBody: classes.scrollBody,
      }}
      aria-labelledby={uuid}
      TransitionComponent={DisableTransitionComponent}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { sx: { transition: "none !important" } },
      }}
    >
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item style={{ paddingTop: "8px", paddingLeft: "8px" }}>
          {onBack && (
            <IconButton onClick={() => onBack()}>
              <ArrowBack titleAccess="Back" />
            </IconButton>
          )}
        </Grid>
        <Grid item style={{ paddingTop: "8px", paddingRight: "8px" }}>
          {showClose && onClose && (
            <IconButton data-id="close-modal" onClick={() => onClose()}>
              <Close titleAccess="Close" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <DialogTitle classes={{ root: "p-0" }} id={uuid} disableTypography>
        <Box mb={3}>
          <Typography
            component="span"
            color="textSecondary"
            variant="h2"
            align="center"
            className="px-3 d-flex w-100 align-items-center justify-content-center"
          >
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent classes={{ root: "pb-3 pt-1 px-2 px-sm-3" }}>
        {instructions && (
          <Typography variant="body1" color="textSecondary" align="center" className="p-3">
            {instructions}
          </Typography>
        )}
        <div>{children}</div>
      </DialogContent>
    </Dialog>
  );
};

ActionModal.defaultProps = {
  open: true,
  showClose: true,
  disableTransition: false,
};

export default ActionModal;
