import React, {useState} from "react";
import {Form, Formik} from "formik";
import orderBy from "lodash/orderBy";
import {useParams} from "react-router-dom";

import useMutation from "../../../hooks/useMutation";
import useNotification from "../../../hooks/notification";
import {usePermissions} from "../../../contexts/permissions";
import {MAKE_SHOPPING_INVENTORY_REQUEST_MUTATION} from "../../../constants/graphql";
import {ADD_INVENTORY_REQUEST_ATTACHMENT_MUTATION} from "../../../constants/graphql/mutations";
import {isStartDateGreaterEndDate} from "./WizardCheckout/Step2";

const vaStatus = {
  canceled: false,
  completed: false,
  confirmation: false,
  final_approval: false,
  scheduled: false,
  reserved: true,
};

export const getLocationNotes = (locationNotes, locationOrder) =>
  orderBy(
    Object.entries(locationNotes).map(([location, note], index) => ({
      location,
      note,
      position: locationOrder[location],
    })),
    "position"
  );

const validate = ({ config, wizardMode, isGuest }) => values => {
  const errors = {};
  if (config.includes("requestor_details")) {
    if (!values.designer) {
      errors.designer = "Required";
    }
  }
  if (config.includes("delivery_details")) {
    if (!values.contactName) {
      errors.contactName = "Required";
    }
    if (!values.contactPhoneNumber) {
      errors.contactPhoneNumber = "Required";
    }
    if (!values.comment) {
      errors.comment = "Required";
    }
    if (!values.startDate && !values.startTime) {
      errors.startDate = "Required";
    }
    if (isStartDateGreaterEndDate(values)) {
      errors.startDate = "Must be less than end date";
    }
  }
  if (config.includes("resources")) {
    if (values.resources.some(v => !v.quantity)) {
      errors.resources = [];
      values.resources.forEach((v, i) => {
        if (!v.quantity) {
          errors.resources[i] = { quantity: "Required" };
        }
      });
    }
  }
  if (!wizardMode && !isGuest) {
    errors.deliveryDate = "Required";
  }

  return errors;
};

const EnableValidationContext = React.createContext(null);

export default function CartFormWizard({
  setErrors,
  initialValues,
  children,
  setSubmitting,
  wizardConfig,
}) {
  const [enableValidation, setEnableValidation] = useState(false);
  const { projectId } = useParams();
  const { notifySuccess } = useNotification();
  const [makeShoppingInventoryRequest] = useMutation(MAKE_SHOPPING_INVENTORY_REQUEST_MUTATION);
  const [inventoryRequestAddAttachment] = useMutation(ADD_INVENTORY_REQUEST_ATTACHMENT_MUTATION);
  const { user } = usePermissions();
  const wizardMode = user.company.settings.wizard_mode;
  const isGuest = user.role === "guest";

  const onSubmit = (values, { setErrors: setFormErrors, setStatus }) => {
    const { files, locationNotes, locationOrder = {}, ...input } = values;
    const urlParams = new URLSearchParams(window.location.search);
    const reserve = urlParams.get("reserve") === "true";

    if (reserve) {
      input.vaStatus = vaStatus;
    }

    if (locationNotes) {
      input.locationNotes = getLocationNotes(locationNotes, locationOrder);
    }

    setSubmitting(true);
    return makeShoppingInventoryRequest({
      variables: { input, projectId },
      onSuccess: ({ inventoryRequest }) => {
        setSubmitting(false);
        setStatus({ success: true });
        notifySuccess("Order has been successfully created!");

        const moveTo = () => {
          if (reserve) {
            return;
          }

          const workOrderNumber = encodeURIComponent(inventoryRequest.workOrderNumber);
          window.location.href = `/shopping/cart/confirmation?id=${inventoryRequest.id}&workOrderNumber=${workOrderNumber}`;
        };

        if (files) {
          return Promise.all(
            files.map(file =>
              inventoryRequestAddAttachment({
                variables: { inventoryRequestId: inventoryRequest.id, file },
              })
            )
          ).then(() => {
            moveTo();
          });
        } else {
          moveTo();
        }
      },
      onFailure: errors => {
        setStatus({ success: false });
        setFormErrors(errors);
        if (setErrors) setErrors(errors);
        setSubmitting(false);
      },
    });
  };

  return (
    <EnableValidationContext.Provider value={setEnableValidation}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={
          enableValidation ? validate({ config: wizardConfig, wizardMode, isGuest }) : undefined
        }
        onSubmit={onSubmit}
      >
        <Form>{children}</Form>
      </Formik>
    </EnableValidationContext.Provider>
  );
}

export const useEnableValidation = () => React.useContext(EnableValidationContext);
