import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {getImage} from "src/views/shopping/Cart/CartItemFields";
import ItemId from "src/views/shopping/Orders/ItemId";
import Dimensions from "src/views/inventory/requests/Dimensions";
import useMutation from "src/hooks/useMutation";
import {UPDATE_INVENTORY_ITEM_MUTATION} from "src/constants/graphql/mutations";
import {getInventoryFields} from "src/views/inventory/requests/CartItemFields";

export default function ManualLocationItem({ item, asset }) {
  const [updateItem, { loading }] = useMutation(UPDATE_INVENTORY_ITEM_MUTATION);

  const handleUpdate = inventoryItem => {
    const variables = {
      id: inventoryItem.id,
      ...getInventoryFields({
        ...inventoryItem,
        pickedStatus: !inventoryItem.pickedStatus,
      }),
    };
    return updateItem({ variables });
  };

  const photo = getImage(asset);
  const highlight = item.quantity > asset?.availableQuantity;
  const background = highlight ? "#fffbf2" : undefined;

  return (
    <TableRow style={{ width: 90 }}>
      <TableCell align="center" style={{ background }}>
        {photo ? <img style={{ maxWidth: 90, maxHeight: 60 }} src={photo} alt="" /> : "No image"}
      </TableCell>
      <TableCell>
        <ItemId asset={asset} />
      </TableCell>
      <TableCell>{asset.description}</TableCell>
      <TableCell>
        <Dimensions dimensions={asset.dimensions} />
      </TableCell>
      <TableCell>{item.quantity}</TableCell>
      <TableCell>
        {item.rackLocations.map((v, index) => (
          <Box key={index}>
            {v.rack_location} ({v.sub_qty})
          </Box>
        ))}
      </TableCell>
      <TableCell align="right">
        <Button
          startIcon={loading && <CircularProgress size={22} />}
          onClick={() => handleUpdate(item)}
          disabled={loading || item.pickedStatus}
          sx={{
            color: item.pickedStatus ? "green" : "red",
            textTransform: "capitalize",
            "&.Mui-disabled": { color: item.pickedStatus ? "green" : "red" },
          }}
        >
          {item.pickedStatus ? "Picked" : "Not Picked"}
        </Button>
      </TableCell>
    </TableRow>
  );
}
