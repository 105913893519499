import sortBy from "lodash/sortBy";

export const STANDARD_PALLET = "Standard Pallet";
export const OVERSIZED_PALLET = "Oversized Pallet";

export const palletTypeOptions = [STANDARD_PALLET, OVERSIZED_PALLET].map(v => [v, v]);

export const shipmentTypes = sortBy([
  "Crate",
  "Container",
  "Box",
  "Envelope",
  "Lift Van",
  STANDARD_PALLET,
  OVERSIZED_PALLET,
  "TWC",
  "Roll",
]);
