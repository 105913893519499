export default {
  size: "medium",
  order: { location_id: "asc" },
  version: "v1.004",
  fields: [
    {
      id: "locationName",
      label: "Location Name",
      visible: true,
    },
    {
      id: "occupied",
      label: "Occupied Status",
      visible: true,
      unEditable: true,
    },
    {
      id: "zone",
      label: "Zone",
      visible: true,
      unEditable: true,
    },
    {
      id: "locationType",
      label: "Location Type",
      visible: true,
      unEditable: true,
    },
    {
      id: "aisle",
      label: "Aisle (Row)",
      visible: true,
      unEditable: true,
    },
    {
      id: "rack",
      label: "Rack (Bay)",
      visible: true,
      unEditable: true,
    },
    {
      id: "shelf",
      label: "Shelf (Level)",
      visible: true,
      unEditable: true,
    },
    {
      id: "slot",
      label: "Slot (Bin)",
      visible: true,
      unEditable: true,
    },
    {
      id: "sqFtOfSlot",
      label: "Slot Sqft",
      visible: true,
      unEditable: true,
    },
    {
      id: "locationId",
      label: "Location ID",
      visible: true,
      unEditable: true,
    },
  ],
};
