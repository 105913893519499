import React, {Fragment, lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import BallotIcon from "@mui/icons-material/Ballot";
import FilterNone from "@mui/icons-material/FilterNone";
import EventIcon from "@mui/icons-material/Event";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LabelImportantOutlinedIcon from "@mui/icons-material/LabelImportantOutlined";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";

import ProjectLayout from "./layouts/ProjectLayout";
import ShoppingLayout from "./layouts/ShoppingLayout";
import LoadingScreen from "./components/LoadingScreen";
import DashboardGuard from "./guards/DashboardGuard";
import GuestGuard from "./guards/GuestGuard";
import InvitationGuard from "./guards/InvitationGuard";
import ShoppingGuard from "./guards/ShoppingGuard";
import CanGuard from "./guards/CanGuard";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const guardProps = route.guard ? { can: route.can } : {};

        return [route.path].flatMap(path => (
          <Route
            key={i}
            path={path}
            exact={route.exact}
            render={props => (
              <Guard {...guardProps}>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        ));
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/auth",
    component: lazy(() => import("src/views/auth/AuthToken")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/layouts/errors/NotFoundView")),
  },
  {
    exact: true,
    path: "/403",
    component: lazy(() => import("src/layouts/errors/PermissionDeniedView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: InvitationGuard,
    path: "/invitation",
    component: lazy(() => import("src/views/auth/InvitationView")),
  },
  {
    exact: true,
    path: "/reset-password",
    component: lazy(() => import("src/views/auth/ResetPasswordView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/forgot-password",
    component: lazy(() => import("src/views/auth/ForgetPasswordView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/shared-planner/:token",
    component: lazy(() => import("src/views/planner/sharedView")),
  },
  {
    path: "/shopping",
    guard: ShoppingGuard,
    layout: ShoppingLayout,
    routes: [
      {
        exact: true,
        path: ["/shopping/profile", "/shopping/profile/security"],
        component: lazy(() => import("./views/shopping/ShoppingProfile/ShoppingProfile")),
      },
      {
        exact: true,
        path: "/shopping/cart/:section?",
        component: lazy(() => import("src/views/shopping/Cart/Page")),
      },
      {
        exact: true,
        path: ["/shopping/reservations", "/shopping/orders"],
        component: lazy(() => import("src/views/shopping/Orders/Page")),
      },
      {
        exact: true,
        path: ["/shopping", "/shopping/:projectId"],
        component: lazy(() => import("src/views/shopping/ProjectAssets/Page")),
      },
    ],
  },
  {
    path: "/",
    guard: DashboardGuard,
    layout: ProjectLayout,
    routes: [
      {
        exact: true,
        path: "/dashboard",
        component: lazy(() => import("src/views/root/Page")),
      },
      {
        exact: true,
        path: "/kitting",
        component: lazy(() => import("src/views/kitting/Kitting")),
      },
      {
        exact: true,
        path: ["/labels", "/projects/:projectId/labels", "/clients/:clientId/labels"],
        component: lazy(() => import("src/views/labels/index")),
      },
      {
        exact: true,
        path: "/notifications",
        component: lazy(() => import("src/views/notifications/Page")),
      },
      {
        exact: true,
        path: "/profile",
        component: lazy(() => import("src/views/users/PageProfile")),
      },
      {
        exact: true,
        path: "/profile/:tab",
        component: lazy(() => import("src/views/users/PageProfile")),
      },
      {
        exact: true,
        path: "/clients",
        component: lazy(() => import("src/views/clients/Page")),
      },
      {
        exact: true,
        path: ["/inventory", "/inventory/:view"],
        component: lazy(() => import("src/views/inventory/Page")),
        guard: CanGuard(["features.inventory_tracking"]),
      },
      {
        exact: true,
        path: ["/calendar", "/calendar/:view"],
        component: lazy(() => import("src/views/inventory/Page")),
        guard: CanGuard(["features.inventory_tracking"]),
      },
      {
        exact: true,
        path: "/client/:clientId/edit",
        component: lazy(() => import("src/views/clients/PageEdit")),
        guard: CanGuard(["clients.write"]),
      },
      {
        exact: true,
        path: "/projects",
        component: lazy(() => import("src/views/projects/Page")),
      },
      {
        exact: true,
        path: "/project/:projectId/edit",
        component: lazy(() => import("src/views/projects/PageEdit")),
        guard: CanGuard(["projects.write"]),
      },
      {
        exact: true,
        path: "/users",
        component: lazy(() => import("src/views/users/Page")),
      },
      {
        exact: true,
        path: "/user/:userId/edit",
        component: lazy(() => import("src/views/users/PageEdit")),
        guard: CanGuard(["users.write"]),
      },
      {
        path: "/settings",
        component: lazy(() => import("src/views/settings/Settings")),
      },
      {
        path: "/dashboard/:projectId/inventory-management/:assetId",
        component: lazy(() => import("src/views/asset/Page")),
        guard: CanGuard(["features.capture_inventory"]),
      },
      {
        exact: true,
        path: ["/projects/:projectId/templates/new", "/clients/:clientId/templates/new"],
        component: lazy(() => import("./views/favoriteReport/PageEdit")),
        guard: CanGuard(["features.reporting"]),
      },
      {
        exact: true,
        path: [
          "/projects/:projectId/templates/duplicate/:reportId",
          "/clients/:clientId/templates/duplicate/:reportId",
        ],
        component: lazy(() => import("./views/favoriteReport/PageEdit")),
        guard: CanGuard(["features.reporting"]),
      },
      {
        exact: true,
        path: ["/projects/:projectId/templates/:reportId"],
        component: lazy(() => import("src/views/favoriteReport/PageEdit")),
        guard: CanGuard(["features.reporting"]),
      },
      {
        exact: true,
        path: "/assets/:assetId",
        component: lazy(() => import("src/views/asset/Page")),
        guard: CanGuard(["assets.read"]),
      },
      {
        exact: true,
        path: ["/projects/:projectId/:tab", "/clients/:clientId/:tab"],
        component: lazy(() => import("src/layouts/ProjectLayout/Page")),
      },
      {
        exact: true,
        path: "/warehouse",
        component: lazy(() => import("src/views/warehouse/WarehouseList")),
      },
      {
        exact: true,
        path: "/warehouse/:id/:view",
        component: lazy(() => import("src/views/warehouse/Warehouse")),
      },
      {
        exact: true,
        path: "/permissions/*",
        component: lazy(() => import("src/views/permissionGrid/Page")),
      },
      {
        exact: true,
        path: "/insights",
        component: lazy(() => import("src/views/inventoryStatistic/Page")),
      },
      {
        exact: true,
        path: "/insights/:id",
        component: lazy(() => import("src/views/inventoryStatistic/Page")),
      },
      {
        exact: true,
        path: "/planners",
        component: lazy(() => import("src/views/planner/list")),
        guard: CanGuard(["features.equipment_planner"]),
      },
      {
        exact: true,
        path: "/planner/:id",
        component: lazy(() => import("src/views/planner/adminView")),
        guard: CanGuard(["features.equipment_planner"]),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export const NAV_BAR_ITEMS = [
  {
    title: "Assets",
    to: "/dashboard",
    icon: DashboardIcon,
    canRules: ["pages.dashboard"],
  },
  {
    title: "Warehouse",
    to: "/warehouse",
    icon: WarehouseOutlinedIcon,
    canRules: ["settings.warehouse"],
  },
  {
    title: "Kitting",
    to: "/kitting",
    icon: GroupWorkOutlinedIcon,
    canRules: ["features.kitting"],
  },
  {
    title: "Planner",
    to: "/planners",
    icon: PictureInPictureIcon,
    canRules: ["features.equipment_planner"],
  },
  {
    title: "Labels",
    to: "/labels",
    icon: LabelImportantOutlinedIcon,
    canRules: ["settings.labels"],
  },
  {
    title: "Store",
    to: "/shopping",
    icon: ShoppingCartOutlinedIcon,
    canRules: ["pages.shopping_cart", "features.inventory_tracking"],
  },
  {
    title: ["va.glimpseit.com", "va.glimpseware.com"].includes(window.location.host) ? "Task Orders" : "Orders",
    to: "/inventory",
    icon: BallotIcon,
    canRules: ["features.inventory_tracking"],
  },
  {
    title: "Reservations",
    to: "/inventory/reserved",
    icon: FilterNone,
    canRules: ["features.inventory_tracking"],
  },
  {
    title: "Calendar",
    to: "/calendar",
    icon: EventIcon,
    canRules: ["features.inventory_tracking"],
  },
  {
    title: window.location.host === "onemedical.glimpseit.com" ? "Reports" : "Insights",
    to: "/insights",
    icon: EqualizerIcon,
    canRules: [],
  },
];

export default routes;
