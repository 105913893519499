import React from "react";
import sumBy from "lodash/sumBy";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";

import useAuth from "../../../hooks/useAuth";
import Input from "../../form/Input";
import RackLocations from "./RackLocations";

export function getRestockTotal(locations) {
  return sumBy(locations, "restock_sub_qty");
}

export default function Fields({
  editLocation,
  asset,
  values,
  warehouseLocations,
  loadingWarehouseLocations,
}) {
  const { user } = useAuth();
  const { settings } = user.company;
  const restockTotal = getRestockTotal(values.locations);
  const newOpenStockQty = asset.availableQuantity + (restockTotal || 0);

  return (
    <>
      {settings.restock_additional_fields && (
        <>
          <Grid item xs={6}>
            <Input name="trackingNumber" label="Tracking Number" />
          </Grid>
          <Grid item xs={6}>
            <Input name="carrierName" label="Carrier Name" />
          </Grid>
          <Grid item xs={6}>
            <Input name="senderName" label="Sender Name" />
          </Grid>
          <Grid item xs={6}>
            <Input name="orderNumber" label="Order Number" />
          </Grid>
          <Grid item xs={6}>
            <Input name="poNumber" label="PO#" />
          </Grid>
        </>
      )}
      {!settings.receiving_wizard && ( // If receiving wizard is enabled, don't show notes field
        <>
          <Grid item xs={settings.restock_additional_fields ? 6 : 12}>
            <Input name="notes" label="Receiving Notes" />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <RackLocations
          locations={
            editLocation ? values.locations.map(v => ({ ...v, new: true })) : values.locations
          }
          warehouseLocations={warehouseLocations}
          loadingWarehouseLocations={loadingWarehouseLocations}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <TextField
            disabled
            size="small"
            variant="outlined"
            label="Restock Total"
            value={restockTotal || ""}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <TextField
            disabled
            size="small"
            variant="outlined"
            label={`New ${user.company.settings.quantity_label}`}
            value={newOpenStockQty}
          />{" "}
        </Box>
      </Grid>
    </>
  );
}
