import React, { useState } from "react";
import { useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AssetQuantityField from "../../../../../views/inventory/requests/AssetQuantityField";
import { shipmentTypes } from "../../../../../constants/receiving-wizard";

const initReceivingUnits = { shipment_type: "", received: 1 };

export default function TotalReceivingUnitsForm({ onClose }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const { values, setFieldValue } = useFormikContext();
  const initialValues = values.totalPieces.length === 0 ? [initReceivingUnits] : values.totalPieces;
  const [totalPieces, setTotalPieces] = useState(initialValues);

  const handleSubmit = () => {
    setFieldValue("totalPieces", totalPieces);
    onClose();
  };

  return (
    <>
      <Grid container spacing={isMobile ? 1 : 2}>
        {totalPieces.map((location, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={6} sm={5}>
                <FormControl size="small" fullWidth>
                  <InputLabel id={`shipment-type-${index}`}>Unit Type</InputLabel>
                  <Select
                    labelId={`shipment-type-${index}`}
                    value={totalPieces[index].shipment_type}
                    label="Unit Type"
                    onChange={(e, value) => {
                      setTotalPieces(
                        totalPieces.map((item, i) =>
                          i === index ? { ...item, shipment_type: e.target.value } : item
                        )
                      );
                    }}
                  >
                    {shipmentTypes.map(value => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={7}>
                <Box display="flex" justifyContent="">
                  <Box ml={-1} display="flex" alignItems="center">
                    <AssetQuantityField
                      displayButtons={!isMobile}
                      size="medium"
                      quantity={totalPieces[index].received}
                      onChange={quantity => {
                        setTotalPieces(
                          totalPieces.map((item, i) =>
                            i === index ? { ...item, received: quantity } : item
                          )
                        );
                      }}                      
                      quantityProps={{
                        label: "# Received",
                        style: { minWidth: 90 },
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                    />
                  </Box>
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setTotalPieces(totalPieces.filter((_, i) => i !== index));
                    }}
                  >
                    <DeleteIcon color="action" />
                  </IconButton>
                </Box>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Box mt={2} display="flex">
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            // setFieldValue("totalPieces", [...values.totalPieces, initReceivingUnits]);
            setTotalPieces([...totalPieces, initReceivingUnits]);
          }}
          startIcon={<AddIcon />}
        >
          Add More
        </Button>
      </Box>
      <Box mt={4} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          disabled={!totalPieces.every(item => item.received && item.shipment_type)}
          sx={{ textTransform: "none" }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Box>
    </>
  );
}
