import React from "react";

import {
  destinationInfo,
  electricalInfo,
  getInventoryItems,
  getOrders,
  getshoppingCart,
  getWarehouseInfo,
  inventoryRequest,
  mechanicalInfo,
  originInfo,
  plumbingInfo,
  reservations,
} from "../../constants/fields";
import IncludeCheckboxes from "./IncludeCheckboxes";
import labels from "../../constants/displayFields";
import useAuth from "src/hooks/useAuth";
import useAllFields from "src/hooks/useAllFields";

export default function IncludeReportFields({ component, hideFields = [] }) {
  const { user } = useAuth();
  const { settings: companySettings } = user.company;
  const { identifiers, descriptiveInfo, otherInfo } = useAllFields();
  const inventoryItems = getInventoryItems(companySettings);

  return (
    <IncludeCheckboxes
      component={component}
      hideFields={hideFields}
      descriptiveInfo={descriptiveInfo}
      destinationInfo={destinationInfo.map(value =>
        value[0] === "destination_room_number" && companySettings.multi_destination_room_number
          ? ["destination_room_numbers", "destination_info", labels.destination_room_numbers]
          : value
      )}
      electricalInfo={electricalInfo}
      getOrders={getOrders}
      getWarehouseInfo={getWarehouseInfo}
      identifiers={identifiers}
      inventoryItems={inventoryItems}
      inventoryRequest={inventoryRequest}
      mechanicalInfo={mechanicalInfo}
      originInfo={originInfo}
      otherInfo={otherInfo}
      plumbingInfo={plumbingInfo}
      reservations={reservations}
      getshoppingCart={getshoppingCart}
    />
  );
}
