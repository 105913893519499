import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import {isReactComponent} from "../../utils/detect";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  marginTop: "4px",
  // change background colour if dragging
  background: isDragging ? "#d5d5d5" : "#fafafa",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  width: "100%",
  // background: isDraggingOver ? "lightblue" : undefined,
  padding: grid,
});
export default function Order({ fields: initHeaders, onChange }) {
  const [fields, setFields] = useState(initHeaders);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(v => !v);
  };

  const handleUpdate = newFields => onChange({ fields: newFields });

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newHeaders = reorder(fields, result.source.index, result.destination.index);
    setFields(newHeaders);
    handleUpdate(newHeaders);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {fields.map((field, index) => {
              const showField = field.visible && !field.inactive && field.id !== "BULK_CHECKBOX";

              return (
                <Draggable key={field.id} draggableId={field.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      disablePadding
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      sx={{ p: 0, display: showField ? "block" : "none" }}
                    >
                      <ListItemButton>
                        {field.label && isReactComponent(field.label) ? (
                          <field.label />
                        ) : (
                          field.label
                        )}
                      </ListItemButton>
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
