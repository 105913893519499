import React, {useState} from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@mui/material/Box";

import ImageModal from "../../../components/images/ImageModal";
import useNotification from "../../../hooks/notification";
import {useAssets} from "../../../contexts/assets";
import {useProjectInventory} from "../../../contexts/projectInventory";
import {sortImages} from "../../../utils/images";

import ImageDND from "./ImageDND";
import ImagesSlider from "./ImagesSlider";

export default function Images({ asset, disabledForm }) {
  const { updateImage, bulkCreateImages, removeImage } = useAssets();
  const { notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const list = sortImages(asset?.images);
  const [open, setOpen] = useState(null);
  const defaultIndex = list.findIndex(v => v.default);
  const [index, setIndex] = useState(defaultIndex === -1 ? 0 : defaultIndex);
  const { updateInventoryAsset } = useProjectInventory();

  const handleOpen = () => {
    if (disabledForm) return;

    if (list.length > 0) {
      setOpen(true);
    }
  };

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { ...variables, assetId: asset?.id },
      onSuccess({ asset }) {
        updateInventoryAsset(asset.id, asset);
        onSuccess({ asset });
      },
      onFailure,
    });
  };

  const handleUpload = images => {
    setLoading(true);
    handleBulkCreateImages({
      variables: { assetId: asset.id, images },
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoading(false);
      },
    });
  };

  const handleRemove = ({ onSuccess, ...values }) => {
    removeImage({
      ...values,
      onSuccess: ({ asset }) => {
        updateInventoryAsset(asset.id, asset);
        onSuccess({ asset });
      },
    });
  };

  return (
    <>
      <Box position="relative" onClick={handleOpen}>
        {list.length === 0 && (
          <>
            {loading && <LinearProgress />}
            <ImageDND onDrop={handleUpload} />
          </>
        )}
        <ImagesSlider list={list} index={index} onChangeIndex={setIndex} />
      </Box>
      {open && list[index] && (
        <ImageModal
          open
          closeOnSave={true}
          selectedId={list[index].id}
          asset={asset}
          images={list}
          onClose={() => setOpen(false)}
          onRemove={handleRemove}
          onUpdate={updateImage}
          onCreate={handleBulkCreateImages}
        />
      )}
    </>
  );
}
