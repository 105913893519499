import React from "react";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useStorageEnabled} from "./StorageEnabledProvider";

const styles = {
  tabs: { minHeight: 40 },
  tab: {
    minHeight: 40,
    textTransform: "none",
    minWidth: "fit-content",
    flex: 1,
  },
};

const sections = [
  { name: "billing", label: "Billing", storage: true },
  { name: "inbound", label: "Inbound", storage: true },
  { name: "outbound", label: "Outbound", storage: true },
  { name: "users", label: "Users" },
  { name: "reports", label: "Reports" },
  { name: "settings", label: "Settings" },
];

export default function ProjectNav({ value, onChange }) {
  const { projectId } = useParams();
  const [storageEnabled] = useStorageEnabled();
  const list = sections
    .filter(v => (storageEnabled ? true : !v.storage))
    .filter(v => (projectId ? true : v.name !== "settings"));

  const handleChangeTab = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box mt={1.5} bgcolor="white">
      <Tabs variant="scrollable" value={value} onChange={handleChangeTab} sx={styles.tabs}>
        {list.map(v => (
          <Tab key={v.name} value={v.name} label={v.label} sx={styles.tab} />
        ))}
      </Tabs>
    </Box>
  );
}
