import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useAuth from "../../hooks/useAuth";
import ReceivingWizardModal from "../../components/capture/ReceivingWizard/ReceivingWizardModal";
import { useAssetsCollection } from "../../contexts/assetsCollection";

export default function CaptureActionsMobile({
  disabled,
  onSave,
  onCreate,
  onDuplicate,
  onRemove,
  onTransfer,
  projectId,
  disableSave,
}) {
  const { handeRefetch } = useAssetsCollection();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { user } = useAuth();
  const { settings } = user.company;

  const handleToggle = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    if (params.get("receiving")) {
      handeRefetch()
      history.push({ pathname, search: "" });
    } else {
      params.set("step", "details");
      params.set("receiving", "true");
      history.push({ pathname, search: params.toString() });
    }
  };

  const handleCreate = () => {
    if (settings.receiving_wizard) {
      handleToggle();
    } else {
      onCreate();
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          {onDuplicate && (
            <Grid item xs={4}>
              <Button
                disabled={disabled || !onDuplicate || disableSave}
                id="duplicateAsset"
                data-id="duplicate-asset"
                fullWidth
                variant="outlined"
                onClick={onDuplicate}
              >
                Duplicate
              </Button>
            </Grid>
          )}
          {onRemove && (
            <Grid item xs={onTransfer ? 3 : 4}>
              <Button
                disabled={disabled || !onRemove}
                id="RemoveAsset"
                data-id="remove-asset"
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={onRemove}
              >
                Remove
              </Button>
            </Grid>
          )}
          {onTransfer && (
            <Grid item xs={3}>
              <Button
                disabled={disabled || !onTransfer}
                id="RemoveAsset"
                data-id="remove-asset"
                fullWidth
                variant="outlined"
                onClick={onTransfer}
              >
                Transfer
              </Button>
            </Grid>
          )}
          {onSave && (
            <Grid item xs={onTransfer ? 3 : 4}>
              <Button
                disabled={disabled || !onSave || disableSave}
                id="saveAsset"
                data-id="save-asset"
                fullWidth
                variant="outlined"
                onClick={onSave}
              >
                Save
              </Button>
            </Grid>
          )}
          {onCreate && (
            <Grid item xs={onTransfer ? 3 : 4}>
              <Button
                disabled={disabled || !projectId || !onCreate}
                id="addNewAsset"
                data-id="add-new-asset"
                fullWidth
                variant="contained"
                onClick={handleCreate}
                color="primary"
              >
                Add New
              </Button>
            </Grid>
          )}
          {params.get("receiving") && <ReceivingWizardModal onToggle={handleToggle} />}
        </Grid>
      </CardContent>
    </Card>
  );
}
