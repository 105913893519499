import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import sumBy from "lodash/sumBy";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { OVERSIZED_PALLET, STANDARD_PALLET } from "../../../../../constants/receiving-wizard";
import AssetImage from "../AssetImage";
import PalletQuantitiesError from "./PalletQuantitiesError";

export default function ConfirmPalletQuantitiesTable({ shipment, shipmentItems, onClose }) {
  const history = useHistory();
  const [error, setError] = useState(false);
  const standardPallets = shipmentItems
    .filter(v => v.asset.palletType === STANDARD_PALLET)
    .flatMap(v => v.asset.rackLocations).length;
  const oversizedPallets = shipmentItems
    .filter(v => v.asset.palletType === OVERSIZED_PALLET)
    .flatMap(v => v.asset.rackLocations).length;
  const totalStandardPallets = sumBy(
    shipment.totalPieces.filter(v => v.shipment_type === STANDARD_PALLET),
    v => v.received
  );
  const totalOversizedPallets = sumBy(
    shipment.totalPieces.filter(v => v.shipment_type === OVERSIZED_PALLET),
    v => v.received
  );

  const handleNext = () => {
    if (totalStandardPallets === standardPallets && totalOversizedPallets === oversizedPallets) {
      const pathname = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      params.set("step", "os&d");
      params.delete("palletQuantities");
      history.push({ pathname, search: params.toString() });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell sx={{ width: 150 }}>Pallet Type</TableCell>
              <TableCell align="right">Pallet Quantity</TableCell>
            </TableRow>
          </TableHead>
          {shipmentItems.map(v => (
            <TableRow key={v.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <AssetImage width={70} height={60} image={v.asset.images[0]?.url} />
              </TableCell>
              <TableCell>{v.asset.description}</TableCell>
              <TableCell>{v.asset.availableQuantity}</TableCell>
              <TableCell>{v.asset.palletType}</TableCell>
              <TableCell align="right">{v.asset.rackLocations.length}</TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
      <Box mt={2} textAlign="right" fontWeight={500}>
        Standard Pallets: {standardPallets}
        <br />
        Oversized Pallets: {oversizedPallets}
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-end">
        <Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleNext}>
          Validate
        </Button>
      </Box>
      {error && (
        <PalletQuantitiesError
          totalStandardPallets={totalStandardPallets}
          totalOversizedPallets={totalOversizedPallets}
          onClose={() => setError(false)}
        />
      )}
    </>
  );
}
