import React, {useState} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import IosShareIcon from "@mui/icons-material/IosShare";

import ShareModal from "src/views/inventory/requests/share/ShareModal"; // Import isVaSubdomain

export default function Share({ title, inventoryRequest }) {
  const [openShareModal, setOpenShareModal] = useState(false);

  const toggleShareModal = () => setOpenShareModal(v => !v);

  return (
    <>
      <Tooltip title={title}>
        <IconButton color="inherit" onClick={toggleShareModal}>
          <IosShareIcon />
        </IconButton>
      </Tooltip>
      {openShareModal && (
        <ShareModal inventoryRequest={inventoryRequest} onClose={toggleShareModal} />
      )}
    </>
  );
}
