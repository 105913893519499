import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Input from "src/components/form/Input";

export default function ReportSettings() {
  return (
    <Paper>
      <Box p={2}>
        <Typography fontWeight={500}>Report Settings</Typography>
        <Grid container spacing={1} sx={{ pt: 1.5 }}>
          <Grid item xs={12}>
            <Input name="customerEmailSendList" label="General Notification Send List" />
          </Grid>
          <Grid item xs={12}>
            <Input name="osDEmailSendList" label="OS&D Notification Send List" />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
