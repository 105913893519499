import React from "react";
import Box from "@material-ui/core/Box";

import { replaceCollection } from "../../../components/TablePro/utils";
import ActionModal from "../../../components/modals/action-modal";
import useMutation from "../../../hooks/useMutation";
import { ASSETS_BULK_UPDATE_MUTATION } from "../../../constants/graphql";
import { useAssetsCollection } from "../../../contexts/assetsCollection";
import RackLocationsForm from "./RackLocationsForm";

export default function RackLocationsModal({ open, toggleModal, entity, addAction }) {
  const { setCollection, setLoadingMutation } = useAssetsCollection();
  const [bulkUpdateAssets] = useMutation(ASSETS_BULK_UPDATE_MUTATION);

  const handleSave = values => {
    setLoadingMutation(true);
    replaceCollection(setCollection, { [entity.id]: values });
    bulkUpdateAssets({
      variables: { input: { id: entity.id, ...values } },
      onSuccess: () => {
        setLoadingMutation(false);
        toggleModal();
        if (addAction) {
          addAction([
            {
              [entity.id]: {
                rackLocations: entity.rackLocations,
              },
            },
            { [entity.id]: values },
          ]);
        }
      },
    });
  };

  return open ? (
    <ActionModal
      open
      title="Storage Location(s)"
      maxWidth={640}
      onClose={toggleModal}
      disableTransition
    >
      <Box mt={1} />
      <RackLocationsForm
        asset={entity}
        rackLocations={entity.rackLocations || []}
        toggleModal={toggleModal}
        onSubmit={handleSave}
      />
    </ActionModal>
  ) : null;
}
