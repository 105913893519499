import React, {useEffect, useRef, useState} from "react";
import {LinearProgress, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Backdrop from "@mui/material/Backdrop";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import Close from "@material-ui/icons/Close";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

import {getDegree, getImageDegree, rotateImage} from "../../utils/images";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import UploadFileButton from "../UploadFileButton";

const deg = { 0: 0, 90: 90, 180: 180, 270: 270 };

const useStyles = makeStyles(theme => ({
  name: {
    marginTop: theme.spacing(1),
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
  },
  avatar: {
    height: 100,
    width: 100,
  },
  content: {
    padding: 0,
  },
  dialog: {
    maxWidth: "80vw",
  },
  dialogContent: {
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
  },
}));

const DisableTransitionComponent = ({ children }) => children;

const ImageModal = ({
  open,
  edit = true,
  images,
  asset,
  closeOnSave = false,
  selectedId: defaultId,
  onClose,
  onRemove,
  onUpdate,
  onCreate,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const [selectedId, setSelectedId] = useState(defaultId);
  const [imageHeight, setImageHeight] = useState("auto");
  const imageRef = useRef();

  const currentImage = images.find(image => selectedId === image.id) || images[0];
  const currentImageIndex = images.indexOf(currentImage);
  const InitialRotation = getImageDegree(currentImage?.url);
  const [defaultImage, setDefaultImage] = useState(currentImage?.default);
  const [rotate, setRotate] = useState(InitialRotation);

  const changed = InitialRotation !== rotate || currentImage?.default !== defaultImage;

  const prevImage = images[currentImageIndex - 1];
  const nextImage = images[currentImageIndex + 1];

  const handleToggleModal = () => setDeleteImage(value => !value);

  const handleRotate = value => {
    const newRotate = deg[rotate + value];

    if (typeof newRotate === "number") {
      setRotate(newRotate);
    } else {
      setRotate(value > 0 ? deg[0] : deg[270]);
    }
  };

  useEffect(() => {
    if (currentImage?.id) {
      setDefaultImage(currentImage?.default);
      setRotate(getImageDegree(currentImage?.url));
    }
  }, [currentImage?.id, currentImage?.default]);

  useEffect(() => {
    setSelectedId(defaultId);
  }, [defaultId]);

  useEffect(() => {
    if (imageRef.current) {
      const imgHeight = imageRef.current.clientHeight;
      setImageHeight(imgHeight > window.innerHeight ? window.innerHeight : "auto");
    }
  }, [currentImage?.url, rotate]);

  const handleRemove = () => {
    setLoading(true);
    onRemove({
      variables: { id: currentImage?.id },
      onSuccess: () => {
        if (images.length <= 1) {
          onClose();
        }
        setLoading(false);
        setSelectedId(prevImage?.id || nextImage?.id);
        closeOnSave && onClose();
      },
      onFailure: () => {
        setLoading(false);
      },
    });
  };

  const handleSave = () => {
    setLoading(true);

    onUpdate({
      variables: {
        id: currentImage?.id,
        url: getDegree(currentImage?.url, rotate),
        default: defaultImage,
      },
      onSuccess: () => {
        setLoading(false);
        closeOnSave && onClose();
      },
      onFailure: () => {
        setLoading(false);
      },
    });
  };

  const handleUpload = files => {
    setUploading(true);

    onCreate({
      variables: { images: files },
      onSuccess: () => {
        setUploading(false);
        closeOnSave && onClose();
      },
      onFailure: () => {
        setUploading(false);
      },
    });
  };

  const handleClose = () => {
    if (loading || uploading) return;
    onClose();
  };

  const imgUrl = rotateImage(currentImage?.url, "width", 1024, rotate);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={DisableTransitionComponent}
        classes={{ paper: classes.dialog }}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: { transition: "none !important" },
          },
        }}
      >
        <Grid container justify="space-between" wrap="nowrap">
          <Grid />
          <Grid item style={{ padding: "8px" }}>
            {onClose && (
              <IconButton disabled={loading || uploading} onClick={onClose}>
                <Close titleAccess="Close" />
              </IconButton>
            )}
          </Grid>
        </Grid>

        <DialogContent classes={{ root: classes.dialogContent }} style={{ maxHeight: imageHeight }}>
          <Grid container>
            <Grid item xs={12}>
              {edit && (
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                  <IconButton id="rotateImageLeft" onClick={() => handleRotate(-90)}>
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton id="rotateImageRight" onClick={() => handleRotate(90)}>
                    <RotateRightIcon />
                  </IconButton>
                  <Checkbox
                    checked={defaultImage}
                    onChange={event => setDefaultImage(Boolean(event.target.checked))}
                  />
                  <Typography color="textSecondary" variant="body2">
                    Set As Default
                  </Typography>
                </Box>
              )}

              <Box display="flex">
                <Box clone display="flex" flexGrow={1} maxWidth={40}>
                  <Button
                    disabled={loading || !prevImage}
                    onClick={() => setSelectedId(prevImage?.id)}
                  >
                    <NavigateBeforeIcon />
                  </Button>
                </Box>

                {currentImage && (
                  <Box
                    display="flex"
                    flexGrow={1}
                    justifyContent="center"
                    mr={0.5}
                    borderRadius={4}
                    mb={3}
                  >
                    <InnerImageZoom src={imgUrl} zoomSrc={imgUrl} zoomScale={2} />
                  </Box>
                )}

                <Box clone display="flex" flexGrow={1} maxWidth={40}>
                  <Button
                    disabled={loading || !nextImage}
                    onClick={() => setSelectedId(nextImage?.id)}
                  >
                    <NavigateNextIcon />
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              {uploading && <LinearProgress className="mt-2" />}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container>
            <Grid item xs={12} container justify="space-between" spacing={2}>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  disabled={loading || images.length === 0}
                  data-id="delete-image"
                  onClick={handleToggleModal}
                  variant="outlined"
                  className={classes.danger}
                >
                  Delete
                </Button>
              </Grid>

              <Grid item xs={3}>
                <UploadFileButton
                  fullWidth
                  multiple
                  name="imageModalUpload"
                  disabled={loading || uploading}
                  onUpload={handleUpload}
                  variant="outlined"
                  color="primary"
                  accept="image/*"
                >
                  Upload
                </UploadFileButton>
              </Grid>

              <Grid item xs={3}>
                <Button
                  fullWidth
                  disabled={loading || !changed}
                  data-id="save-image"
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {deleteImage && (
        <ConfirmDeleteModal
          title="Delete Image"
          descr="Are you sure you want to delete image?"
          onClose={handleToggleModal}
          onDelete={handleRemove}
        />
      )}
    </>
  );
};

export default ImageModal;
