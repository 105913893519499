import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";

export default function TotalWeightError({ onClose, totalWeight }) {
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Error
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography align="center">
          The calculated total weight from this page does not match total weight of the shipment:
          <br /> {totalWeight} lbs.
        </Typography>
        <Typography mt={3} align="center">
          Please make the proper adjustments.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
