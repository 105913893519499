import React from "react";
import {Form, Formik} from "formik";
import orderBy from "lodash/orderBy";
import * as Yup from "yup";
import {useHistory, useParams} from "react-router-dom";
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

import useAuth from "../../../hooks/useAuth";
import useMutation from "../../../hooks/useMutation";
import useNotification from "../../../hooks/notification";
import {isTimeLessThan48Hours} from "../../../utils/inventoryRequests";
import {MAKE_SHOPPING_INVENTORY_REQUEST_MUTATION} from "../../../constants/graphql";
import {ADD_INVENTORY_REQUEST_ATTACHMENT_MUTATION} from "../../../constants/graphql/mutations";
import {isAdminUser} from "../../../constants/user";

const singleAddressCondition = () => {
  return Yup.string().when("deliveryType", {
    is: deliveryType => deliveryType === "multiple",
    then: Yup.string(),
    // otherwise: Yup.string().required(),
    otherwise: Yup.string(),
  });
};

const requiredAddressSchema = Yup.object().shape({
  // street: Yup.string().required(),
  // city: Yup.string().required(),
  // zipCode: Yup.string().required(),
  street: Yup.string(),
  city: Yup.string(),
  zipCode: Yup.string(),
  comment: Yup.string(),
  assetId: Yup.string(),
});

export const validationSchema = ({ email }) =>
  Yup.object().shape({
    street: singleAddressCondition(),
    city: singleAddressCondition(),
    zipCode: singleAddressCondition(),
    comment: Yup.string(),
    // email: email ? Yup.string().email().required("Email is required"): Yup.string(),
    addresses: Yup.mixed().when("deliveryType", {
      is: deliveryType => deliveryType === "multiple",
      then: Yup.array().of(requiredAddressSchema),
      otherwise: Yup.array(),
    }),
  });

const CartForm = ({ setErrors, initialValues, children, setSubmitting, stripe, email }) => {
  const { projectId } = useParams();
  const { notifySuccess } = useNotification();
  const [makeShoppingInventoryRequest] = useMutation(MAKE_SHOPPING_INVENTORY_REQUEST_MUTATION);
  const history = useHistory();
  const { user } = useAuth();
  const { notifyError } = useNotification();
  const isAdmin = isAdminUser(user.email);

  const [inventoryRequestAddAttachment] = useMutation(ADD_INVENTORY_REQUEST_ATTACHMENT_MUTATION);

  const onSubmit = (values, { setErrors: setFormErrors, setStatus }) => {
    if (!isAdmin && isTimeLessThan48Hours(values)) {
      return notifyError("Please select a date and time at least 48 hours in advance");
    }

    const { file, locationNotes, locationOrder = {}, ...input } = values;
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("reserve")) {
      input.vaStatus = {
        canceled: false,
        completed: false,
        confirmation: false,
        final_approval: false,
        scheduled: false,
        reserved: true,
      };
    }

    if (locationNotes) {
      input.locationNotes = orderBy(
        Object.entries(locationNotes).map(([location, note], index) => ({
          location,
          note,
          position: locationOrder[location],
        })),
        "position"
      );
    }

    setSubmitting(true);
    makeShoppingInventoryRequest({
      variables: { input, projectId },
      onSuccess: ({ stripeCheckoutSessionId, inventoryRequest }) => {
        setSubmitting(false);
        setStatus({ success: true });
        notifySuccess("Inventory Request has been successfully created!");

        if (file) {
          inventoryRequestAddAttachment({
            variables: { inventoryRequestId: inventoryRequest.id, file },
          });
        }

        if (stripeCheckoutSessionId && user.stripePayment) {
          stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
        } else {
          if (user.company.settings.wizard_mode) {
            const workOrderNumber = encodeURIComponent(inventoryRequest.workOrderNumber);
            window.location.href = `/shopping/cart/confirmation?id=${inventoryRequest.id}&workOrderNumber=${workOrderNumber}`;
          } else {
            projectId ? history.push(`/shopping/${projectId}`) : history.push(`/shopping`);
          }
        }
      },
      onFailure: errors => {
        setStatus({ success: false });
        setFormErrors(errors);
        if (setErrors) setErrors(errors);
        setSubmitting(false);
      },
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema({ email })}
      onSubmit={onSubmit}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default props => {
  const { user } = useAuth();
  const loadedStripe = loadStripe(user.stripePublicToken);

  return (
    <Elements stripe={loadedStripe}>
      <ElementsConsumer>
        {({ elements, stripe }) => <CartForm {...props} stripe={stripe} />}
      </ElementsConsumer>
    </Elements>
  );
};
